<template>
  <Page label="Products" withMargin>
    <div class="flex flex-col -mx-4">
      <div
        v-for="(item, i) in items"
        :key="i"
        @click="onClick(item)"
        class="w-full mt-8 cursor-pointer"
      >
        <div
          class="rounded-12 flex flex-col justify-between items-center mx-4 overflow-y-visible px-6 py-8 pointer-events-none bg-brand-base bg-brand-background"
          :class="{
            'bg-lima': item.type === 'lima',
            'bg-mathys': item.type === 'mathys',
          }"
        >
          <span class="font-helvetica-bold text-black text-16 text-uppercase">
            {{ item.label }}
          </span>
        </div>
      </div>
    </div>
    <separator v-if="catalogues?.length" />
    <div v-if="catalogues?.length">
      <document-list-item
        v-for="(item, i) in catalogues"
        :key="i"
        :document="item"
      />
    </div>
  </Page>
</template>

<script>
import Page from "../../components/Page.vue";
import Separator from "../../components/Separator.vue";
import DocumentListItem from "../../components/DocumentListItem.vue";
import messages from "@/messages";
import urls from "@/urls";
import MatomoManager from "../../mixins/MatomoManager.vue";

export default {
  components: {
    Page,
    Separator,
    DocumentListItem,
  },
  data() {
    return {
      catalogues: [],
      ctaUrls: {},
    };
  },
  computed: {
    items() {
      return [
        {
          label: "Legacy Lima",
          path: "/products",
          type: "lima",
        },
        {
          label: "Legacy Mathys",
          link: this.ctaUrls.mathys_cta,
          type: "mathys",
        },
      ];
    },
  },
  mixins: [MatomoManager],
  created() {
    this.getProducts();
    this.$store.dispatch("setCtaUrls");
  },
  methods: {
    onClick(item) {
      if (item.path) {
        this.$router.push(item.path);
      } else {
        this.logEvent("Link", "open Mathys link", `Link ${item.label}`);
        window.open(item.link);
      }
    },
    async getProducts() {
      try {
        const prod = await this.$http({
          method: "GET",
          url: urls.products.segments,
          params: {},
        });
        if (prod) {
          if (prod.categories) {
            this.segments = prod.categories;
          }
          if (prod.catalogue) {
            this.catalogue = prod.catalogue;
          }
          if (prod.catalogues?.length) {
            this.catalogues = prod.catalogues;
          }
        }
      } catch (e) {
        console.error(e);
        this.$toast({
          message: messages.errors.segments,
          color: "danger",
        });
      }
    },
  },
  watch: {
    "$store.getters.ctaUrls": {
      handler(newValue) {
        if (this.$store.getters.ctaUrls) {
          this.ctaUrls = newValue;
        }
      },
      deep: true,
    },
  },
};
</script>
