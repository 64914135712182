<template>
  <ion-modal
    animated
    showBackdrop
    css-class="custom-modal no-modal-shadow"
    :is-open="isOpened"
    @didDismiss="closeSubsidiary"
  >
    <div
      class="w-full h-full relative pt-20 gradient-background relative safe-area-top-margin"
      style="min-width: 100%"
    >
      <div
        class="bg-transparent absolute top-0 left-0 flex px-8 w-full z-10"
        style="height: 90px"
      >
        <!-- close button -->
        <ion-button
          type="button"
          class="relative mr-auto my-auto back-button"
          @click="closeSubsidiary"
        >
          <ion-img
            src="/assets/button-icons/close.svg"
            className="pointer-events-none"
          />
        </ion-button>
      </div>
      <div class="mx-8 flex flex-col relative z-10">
        <Title titleClass="pb-4 text-black font-helvetica-bold text-28 block">
          {{ updatedSubsidiary?.title }}
        </Title>
        <div class="flex flex-col mb-4">
          <span class="font-helvetica text-14 text-grey spacing-44 line-24 mb-2"
            >Address</span
          >
          <p
            class="m-0 paragraph font-helvetica text-16 text-mid-dark-grey spacing-1 line-24"
            v-html="
              updatedSubsidiary && updatedSubsidiary.address
                ? updatedSubsidiary.address
                : ''
            "
          ></p>
        </div>
        <div class="flex flex-col">
          <div v-if="updatedSubsidiary?.phone" class="relative flex mb-4">
            <a
              :href="'tel:' + updatedSubsidiary.phone"
              title="phone"
              class="absolute top-0 left-0 w-full h-full"
            ></a>
            <square-container
              bgClass="bg-light-grey"
              squareSize="44"
              classes="pointer-events-none flex"
            >
              <ion-img
                src="/assets/button-icons/phone-black.svg"
                class="m-auto"
              />
            </square-container>
            <div
              class="ml-4 flex flex-col justify-between items-start pointer-events-none"
            >
              <span
                class="font-helvetica-medium text-16 text-black spacing-5 line-28"
                >{{ updatedSubsidiary?.phone }}</span
              >
              <span class="font-helvetica text-14 text-grey spacing-44 line-24">
                Phone
              </span>
            </div>
          </div>

          <div v-if="updatedSubsidiary?.fax" class="relative flex mb-4">
            <square-container
              bgClass="bg-light-grey"
              squareSize="44"
              classes="pointer-events-none flex"
            >
              <ion-img
                src="/assets/button-icons/phone-black.svg"
                class="m-auto"
              />
            </square-container>
            <div
              class="ml-4 flex flex-col justify-between items-start pointer-events-none"
            >
              <span
                class="font-helvetica-medium text-16 text-black spacing-5 line-28"
                >{{ updatedSubsidiary?.fax }}</span
              >
              <span class="font-helvetica text-14 text-grey spacing-44 line-24">
                Fax
              </span>
            </div>
          </div>

          <div v-if="updatedSubsidiary?.email" class="relative flex mb-4">
            <a
              :href="'mailto:' + updatedSubsidiary?.email"
              title="mail to"
              class="absolute top-0 left-0 w-full h-full line-break-anywhere"
            ></a>
            <square-container
              bgClass="bg-light-grey"
              squareSize="44"
              classes="pointer-events-none flex"
            >
              <ion-img
                src="/assets/button-icons/mail-black.svg"
                class="m-auto"
              />
            </square-container>
            <div
              class="ml-4 flex flex-col justify-between items-start pointer-events-none"
            >
              <span
                class="font-helvetica-medium text-16 text-black spacing-5 line-28 line-break-anywhere"
                >{{ updatedSubsidiary?.email }}</span
              >
              <span class="font-helvetica text-14 text-grey spacing-44 line-24">
                E-mail
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ion-modal>
</template>

<script>
import { IonImg, IonModal, IonButton } from "@ionic/vue";
import Title from "./Title.vue";
import SquareContainer from "./containers/SquareContainer.vue";
import Platformer from "../mixins/Platformer.vue";
export default {
  components: {
    IonImg,
    Title,
    SquareContainer,
    IonModal,
    IonButton,
  },

  mixins: [Platformer],

  props: {
    subsidiary: Object,
  },
  data() {
    return {
      isOpened: false,
    };
  },
  computed: {
    updatedSubsidiary() {
      return this.subsidiary;
    },
  },
  methods: {
    closeSubsidiary() {
      this.isOpened = false;
      this.$emit("onClose");
    },
  },
  watch: {
    updatedSubsidiary: {
      handler(newValue) {
        if (newValue) {
          this.isOpened = true;
        } else {
          this.isOpened = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.no-modal-shadow .modal-shadow.sc-ion-modal-ios {
  z-index: -1 !important;
}

.safe-area-top-margin {
  margin-top: var(--ion-safe-area-top, 0);
}

.safe-area-top-padding {
  margin-top: var(--ion-safe-area-top, 0);
}
</style>
