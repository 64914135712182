<template>
  <div class="flex flex-col justify-start items-start pt-4">
    <ion-img src="/assets/terms/0001.jpg" className="pointer-events-none" />
    <ion-img
      src="/assets/terms/0002.jpg"
      className="pointer-events-none mt-2"
    />
    <ion-img
      src="/assets/terms/0003.jpg"
      className="pointer-events-none mt-2"
    />
    <ion-img
      src="/assets/terms/0004.jpg"
      className="pointer-events-none mt-2"
    />
    <ion-img
      src="/assets/terms/0005.jpg"
      className="pointer-events-none mt-2"
    />
    <ion-img
      src="/assets/terms/0006.jpg"
      className="pointer-events-none mt-2"
    />
  </div>
</template>
<script>
import { IonImg } from '@ionic/vue'

export default {
  name: 'Terms',
  components: { IonImg }
}
</script>
