<template>
  <Page
    withMargin
    :label="updatedProduct.title"
    :aboveTitle="aboveTitle"
    :back="backPath"
  >
    <div class="bg-white rounded-12 relative gallery-container overflow-hidden">
      <ion-img
        :src="
          updatedProduct.gallery && updatedProduct.gallery[0]
            ? updatedProduct.gallery[0].big
            : ''
        "
        class="h-full w-auto pointer-events-none"
      />
    </div>

    <div class="flex my-8 justify-between items-start">
      <div class="flex flex-col">
        <span class="font-helvetica text-14 text-grey spacing-44 line-20"
          >Material</span
        >
        <span
          class="font-helvetica text-16 text-mid-dark-grey spacing-2 line-26"
          >{{ updatedProduct.material }}</span
        >
      </div>
      <div class="flex flex-col">
        <span class="font-helvetica text-14 text-grey spacing-44 line-20"
          >Sterile</span
        >
        <span
          class="font-helvetica text-16 text-mid-dark-grey spacing-2 line-26"
          >{{ updatedProduct.sterile ? 'Sterile' : 'Not sterile' }}</span
        >
      </div>
    </div>
    <div
      v-if="updatedProduct.notes && updatedProduct.notes.length"
      class="bg-light-grey rounded-12 p-4 flex flex-col mb-8"
    >
      <span class="font-helvetica text-14 text-grey spacing-44 line-20"
        >Notes</span
      >
      <p
        class="m-0 pt-4 font-helvetica text-16 text-mid-dark-grey spacing-1 line-24"
      >
        {{ updatedProduct.notes }}
      </p>
    </div>

    <separator noYMargin />

    <detail-section
      v-for="(group, i) in updatedProduct.variants"
      :key="i"
      :label="group.title"
      noPadding
    >
      <div
        v-for="(variant, j) in group.variants"
        :key="j"
        class="flex mb-8 justify-between items-center"
      >
        <div class="flex justify-start items-stretch">
          <div
            :class="[
              variant.upon_request ? 'bg-red' : 'bg-light-grey',
              ' width-4 rounded-2 mr-4'
            ]"
          ></div>
          <div class="flex flex-col justify-between items-start">
            <span
              class="font-helvetica-medium text-dark-grey text-16 spacing-4 line-24"
              >{{ variant.size }}</span
            >
            <span class="font-helvetica text-grey text-14 spacing-44 line-24">
              {{ variant.code }}
            </span>
          </div>
        </div>
        <div
          v-if="variant.upon_request"
          class="bg-light-red rounded-6 px-2 ml-auto"
        >
          <span
            class="font-helvetica-medium text-12 text-red spacing-8 line-30 pointer-events-none"
            >upon request</span
          >
        </div>
      </div>
    </detail-section>
  </Page>
</template>

<script>
import { IonImg } from '@ionic/vue'
import Page from '../../components/Page.vue'
import Separator from '../../components/Separator.vue'
import DetailSection from '../../components/DetailSection.vue'
import messages from '@/messages'
import urls from '@/urls'
import MatomoManager from '../../mixins/MatomoManager.vue'

export default {
  name: 'ComponentDetail',

  components: {
    IonImg,
    Page,
    Separator,
    DetailSection
  },

  mixins:[MatomoManager],

  data () {
    return {
      product: {}
    }
  },

  computed: {
    updatedProduct () {
      return this.product
    },

    aboveTitle () {
      return this.updatedProduct.parent_name &&
        this.updatedProduct.parent_name.length
        ? this.updatedProduct.parent_name + ' /'
        : null
    },

    backPath () {
      const id = this.updatedProduct.parent_id
      return `/products/detail/${id}/components`
    }
  },

  async created () {
    if (this.$route.params.id) {
      try {
        const instance = await this.$http({
          method: 'GET',
          url: urls.products.component + '/' + this.$route.params.id,
          params: {}
        })
        this.product = instance
        this.logPage(instance.title + ' (Component detail)')
      } catch (e) {
        console.error(e)
        this.$toast({
          message: messages.errors.componentDetail,
          color: 'danger'
        })
      }
    } else {
      console.error('No component id in route')
      this.$toast({
        message: messages.errors.componentDetail,
        color: 'danger'
      })
      this.$router.back()
    }
  }
}
</script>
<style scoped>
.gallery-container {
  width: calc(100%);
  min-width: calc(100%);
  height: 221px;
}
</style>
