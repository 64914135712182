import { Media } from "@capacitor-community/media";

export const media = $app =>
  async function (url, albumName = 'Album' ) {
    await $app.$loading.show()

    const ensureAlbum = async () => {
      const { albums } = await Media.getAlbums();
      let album = albums.find(a => a.name === albumName)
      if (!album) {
        try {
          await Media.createAlbum({ name: albumName })

          // Try to get the new album, avoid recursion and loops
          const { albums } = await Media.getAlbums();
          album = albums.find(a => a.name === albumName)

        } catch (err) {
          console.log(err)
        }
      }
      return album?.identifier || '';
    }

      const opts = { path: url, albumIdentifier: await ensureAlbum() };
      await Media.savePhoto(opts)
      await $app.$loading.hide()
  }

export default {
  install (app) {
    app.config.globalProperties.$media = media(
      app.config.globalProperties
    )
  }
}
