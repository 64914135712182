<template>
  <ion-modal
    animated
    showBackdrop
    css-class="custom-modal"
    :is-open="computedOpen"
    @didDismiss="$emit('onClose')"
  >
    <div
      class="w-full h-full relative pt-20 bg-black flex justify-center items-center"
    >
      <div
        class="bg-black absolute top-0 left-0 flex px-8 w-full z-10"
        style="height:90px"
      >
        <!-- close button -->
        <ion-button
          type="button"
          class="relative mr-auto my-auto back-button"
          @click="closeImage"
        >
          <img
            src="/assets/button-icons/close-white.svg"
            className="pointer-events-none"
          />
        </ion-button>
        <!-- download button -->
        <ion-button
        v-if="isApp"
          type="button"
          class="relative ml-auto my-auto transparent-button"
          @click="onDownload"
        >
          <img
            src="/assets/button-icons/download-white.svg"
            className="pointer-events-none"
          />
        </ion-button>
      </div>
      <swiper
        ref="slides"
        style="position:absolute; top:0; left:0; width:100%; height:100%; z-index:0"
        :zoom="true"
        :speed="400"
        :modules="modules"
        :initial-slide="index"
        >
        <swiper-slide v-for="(slide, i) in updatedGallery" :key="i">
          <div class="flex w-full h-full">
            <div class="swiper-zoom-container">
              <img :src="slide.img" class="w-full mx-auto my-auto pointer-events-none" />
            </div>
            <div v-if="slide.text" class="absolute bottom-0 left-0 p-2 w-full" style="background: rgba(0,0,0,0.7); min-height:100px;">
              <p class="font-helvetica text-white text-14 spacing-44 line-24">
              {{ slide.text }}
              </p>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </ion-modal>
</template>

<script>
import { IonModal, IonButton } from '@ionic/vue'
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Zoom, Pagination } from 'swiper/modules';
import { Capacitor } from '@capacitor/core'

import 'swiper/css';
import 'swiper/css/zoom';
import 'swiper/css/pagination';
import '@ionic/vue/css/ionic-swiper.css';

export default {
  components: {
    Swiper,
    IonModal,
    IonButton,
    SwiperSlide,
  },

  props: {
    open: {
      type: Boolean,
    },

    image: {
      type: Number,
    },

    gallery: {
      type: Array,
    },

    index: {
      type: Number,
      default: 0
    },

    folder: {
      type: String
    }
  },

  data () {
    return {
      swiper: null,
      modules: [Zoom, Pagination]
    }
  },

  methods: {
    closeImage () {
      this.$emit('onClose')
    },

    onDownload () {
      console.log(this.swiper)
      if (this.swiper) {
        const index = this.swiper.activeIndex
        this.downloadImage(this.updatedGallery[index])
      }
      this.downloadImage(this.updatedGallery[this.index || 0])
  },

    async downloadImage (image) {
      try {
        await this.$media(image.img, this.folder)
      } catch (e) {
        console.error(e)
        this.$toast({
          message: 'Cannot download image',
          color: 'danger'
        })
      } finally {
        await this.$loading.hide()
        this.$toast({
          message: 'Image downloaded',
          color: 'dark'
        })
      }
    },

    setSwiperInstance (data) {
      this.swiper = data
    }
  },

  computed: {
    isApp () {
      return this.checkIsApp(Capacitor.getPlatform())
    },

    computedOpen () {
      return this.open
    },

    updatedGallery () {
      if (this.gallery && this.gallery.length) {

        return this.gallery.map(item => ({
          img: item.hd || item.image || item.preview || item.img,
          text: item.label || item.text
        }))
      }
      return []
    }
  }
}
</script>
