<template>
  <ion-app>
    <router-view />
  </ion-app>
</template>

<script>
import { IonApp } from '@ionic/vue'

export default {
  name: 'App',
  components: {
    IonApp
  }
}
</script>
