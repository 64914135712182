<template>
  <div class="flex flex-wrap w-full -mx-2">
    <div
      v-for="(img, i) in images"
      :key="i"
      class="flex w-1/3 mb-4"
      @click="openImage(i)"
    >
      <div
        class="w-full mx-2 squared pointer-events-none bg-light-grey rounded-8 relative overflow-hidden"
      >
        <ion-img :src="img.preview" class="w-full h-full absolute" style="object-fit:cover;" />
      </div>
    </div>
    <image-modal
      v-if="isOpenImage"
      :index="image"
      :folder="folder"
      :gallery="images"
      :open="isOpenImage"
      @onClose="closeImage"
    />
  </div>
</template>
<script>
import { IonImg } from '@ionic/vue'
import ImageModal from '../modals/ImageModal.vue'

export default {
  components: {
    ImageModal,
    IonImg
  },

  props: {
    images: {
      type: Array
    },

    folder: {
      type: String
    }
  },

  data () {
    return {
      image: null,
      isOpenImage: false
    }
  },

  methods: {
    openImage (index) {
      this.image = index
      this.isOpenImage = true
    },

    closeImage () {
      this.isOpenImage = false
      this.image = null
    }
  }
}
</script>

<style>
.squared:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}
</style>
