<template>
  <div
    class="w-full fixed bottom-0 left-0 bg-transparent"
    style="height: 72px;"
  >
    <div class="h-full h-full flex tabs-bar bg-black px-8">
      <div
        v-for="(tab, i) in routingTabs"
        :key="i"
        :tab="tab.name"
        :class="[
          tab.disabled ? 'pointer-events-none' : 'cursor-pointer',
          ' flex flex-grow justify-center items-center'
        ]"
        @click="$router.push(tab.href)"
      >
        <ion-img
          style="width:31px;height:31px;"
          :src="tab.icon"
          :class="tab.active ? '' : tab.disabled ? 'opacity-25' : 'opacity-50'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { IonImg } from '@ionic/vue'

export default {
  components: { IonImg },
  data () {
    return {
      tabs: [
        {
          name: 'home',
          href: '/home',
          icon: '/assets/button-icons/home.svg'
        },
        {
          name: 'products',
          href: '/products/router',
          icon: '/assets/button-icons/products.svg'
        },
        {
          name: 'documents',
          href: '/documents',
          icon: '/assets/button-icons/documents.svg'
        },
        {
          name: 'search',
          href: '/search',
          icon: '/assets/button-icons/search.svg'
        },
        {
          name: 'menu',
          href: '/menu',
          icon: '/assets/button-icons/menu.svg'
        }
      ]
    }
  },
  props: {
    home: Boolean
  },
  computed: {
    routingTabs () {
      const currentRoute = this.$route.path
      const activePath = this.getActivePath(currentRoute)
      return this.tabs.map(route => ({
        ...route,
        active: route.href === activePath
      }))
    }
  },
  methods: {
    getActivePath (route) {
      for (let i = 0; i < this.tabs.length; i++) {
        const path = this.tabs[i].href
        if (route.includes(path)) {
          return path
        }
      }
    }
  }
}
</script>

<style scoped>
.tabs-bar {
  border-radius: 24px 24px 0 0;
}
</style>
