<template>
  <div class="flex flex-col pb-10">
    <div
      v-for="(msg, i) in messages"
      :key="i"
      class="relative flex flex-col mb-16"
    >
      <div
        class="absolute ml-8 mt-8 bg-light-grey h-full"
        style="border-radius: 0 12px 12px 12px;width:calc(100% - 4rem);"
      ></div>
      <ion-img
        src="assets/button-icons/message-triangle.svg"
        class="absolute ml-8 mt-8"
        style="transform:translateY(-100%);width:16px;"
      />
      <div class="flex flex-col mx-16 mt-8 pt-8 relative z-10">
        <span
          class="font-helvetica-bold text-20 text-black spacing-2 line-28 mb-4"
        >
          {{ msg.title }}
        </span>
        <p
          class="m-0 font-helvetica text-16 text-mid-dark-grey spacing-1 line-24"
          v-html="msg.description"
        ></p>
      </div>
      <div v-if="msg.images && msg.images.length" class="mt-4">
        <image-gallery chatGallery :gallery="msg.images" initMargin="ml-16" />
      </div>
    </div>
  </div>
</template>
<script>
import { IonImg } from '@ionic/vue'
import ImageGallery from '../ImageGallery.vue'
export default {
  components: {
    IonImg,
    ImageGallery
  },

  props: {
    instance: Object
  },

  computed: {
    messages () {
      if (
        this.instance &&
        this.instance.content &&
        this.instance.content.length
      ) {
        return this.instance.content
      }
      return []
    }
  }
}
</script>
