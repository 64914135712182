<template>
  <Page label="What's New" back="/menu">
    <carousel id="sections-carousel" classes="pt-4 -my-4 pb-8">
      <section-button
        v-for="(theSection, i) in sections"
        :key="i"
        :active="section === theSection.path"
        :classes="[i === 0 ? 'ml-8' : '', ' mr-4']"
        :label="theSection.label"
        @onClick="routeToSection(theSection.path)"
      />
      <div>&nbsp;</div>
    </carousel>
    <Updates
      v-if="section === 'updates'"
      :filters="updatedFilters"
      @updateFilters="updateFilters"
    />
    <Notifications
      v-if="section === 'notifications'"
      @onChangeNotification="openNotification"
    />
    <notifications-modal
      v-if="notificationOpened !== null"
      :notificationID="notificationOpened"
      @onClose="closeNotification"
    />
  </Page>
</template>

<script>
import Page from '../../components/Page.vue'
import Updates from '../../components/whatsnew/Updates.vue'
import Notifications from '../../components/whatsnew/Notifications.vue'
import NotificationsModal from '../../components/whatsnew/NotificationsModal.vue'
import Carousel from '../../components/Carousel.vue'
import SectionButton from '../../components/containers/SectionButton.vue'
export default {
  components: {
    Page,
    Updates,
    Notifications,
    NotificationsModal,
    Carousel,
    SectionButton
  },
  data () {
    return {
      initSection: 'notifications',
      sections: [
        {
          label: 'Notifications',
          path: 'notifications'
        },
        {
          label: 'App updates',
          path: 'updates'
        }
      ],
      notificationOpened: null
    }
  },
  computed: {
    section () {
      return this.initSection
    }
  },
  created () {
    if (this.$route.query && this.$route.query.section) {
      this.initSection = this.$route.query.section
    }
  },
  methods: {
    routeToSection (path) {
      this.initSection = path
    },
    closeNotification (notification) {
      this.notificationOpened = null
      if (notification?.link) {
        const type = notification.link.type
        const id = notification.link.id

        switch (type) {
          case 'products':
            this.$router.push(`/products/detail${id ? '/' + id : ''}`)
            break
          case 'training':
            this.$router.push(`/training${id ? '/' + id : ''}`)
            break
          case 'learn':
            this.$router.push(`/learn${id ? '/' + id : ''}`)
            break
          case 'congress':
            this.$router.push(`/congresses${id ? '/' + id : ''}`)
            break
          case 'news':
            this.$router.push(`/news${id ? '/' + id : ''}`)
            break
          default:
            this.$router.push(`/${type}`)
            break
        }
      }
      this.$store.dispatch('getNotifications')
    },
    openNotification (notificationID) {
      this.notificationOpened = notificationID
    }
  }
}
</script>
