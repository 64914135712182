<template>
  <div :class="[isVh ? 'swiper-vh' : '', 'flex flex-col relative gradient-background min-h-screen']">
    <swiper
        ref="slides"
        style="position:absolute; top:0; left:0; width:100%; height:100%; z-index:0"
        :speed="400"
        :pagination="true"
        :modules="modules"
        @swiper="setSwiperInstance"
        @slideNextTransitionEnd="onNextEnd"
        @slidePrevTransitionEnd="onPrevEnd"
        >
        <swiper-slide v-for="(slide, i) in computedSlides" :key="i">
          <slot :item="slide"></slot>
      </swiper-slide>
    </swiper>
    <!-- <ion-slides
      mode="ios"
      ref="slides"
      pager="true"
      style="position:absolute; top:0; left:0; width:100%; z-index:0"
      :options="slideOpts"
      @ionSlideNextEnd="onNextEnd"
      @ionSlidePrevEnd="onPrevEnd"
    >
      <ion-slide v-for="(slide, i) in computedSlides" :key="i">
        <slot :item="slide"></slot>
      </ion-slide>
    </ion-slides> -->
    <div class="px-8" style="position:relative;bottom:20px;margin-top:auto;">
      <div v-if="back" class="flex justify-between  items-center px-8">
        <ion-button
          type="button"
          class="mr-4 relative elevated-shadow text-black z-10 "
          style="--background:transparent;"
          v-if="activeSlide > 0"
          @click="previousSlide"
        >
          <ion-img
            src="/assets/button-icons/back.svg"
            class="pointer-events-none"
            style="visibility:visible; width:14px; height:14px;"
          />
        </ion-button>
        <big-button :label="buttonLabel" @onClick="buttonClick" />
        <ion-button
          type="button"
          class="pointer-events-none opacity-0 ml-4 elevated-shadow"
          style="--background:transparent;"
          v-if="activeSlide > 0"
          @click="previousSlide"
        >
          <ion-img
            src="/assets/button-icons/back.svg"
            class="pointer-events-none"
          />
        </ion-button>
      </div>
      <big-button v-else :label="buttonLabel" @onClick="buttonClick" />
    </div>
  </div>
</template>

<script>
import { IonImg, IonButton } from '@ionic/vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Capacitor } from '@capacitor/core';
import { Pagination } from 'swiper/modules';
import BigButton from './containers/BigButton.vue'

import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/vue/css/ionic-swiper.css';

export default {
  components: {
    Swiper,
    IonImg,
    IonButton,
    BigButton,
    SwiperSlide
  },

  props: {
    slides: {
      type: Array
    },

    finalLabel: {
      type: String
    },

    mainButtonLabel: {
      type: String
    },

    back: {
      type: Boolean
    },

    url: {
      type: String
    }
  },

  data () {
    return {
      active: 0,
      slider: null,
      modules: [Pagination]
    }
  },

  computed: {
    isVh () {
      return !Capacitor.getPlatform() || (Capacitor.getPlatform() === 'web' || Capacitor.getPlatform() === 'ios')
    },

    computedSlides () {
      return this.slides
    },

    activeSlide () {
      return this.active
    },

    isLastSlide () {
      if (this.active === this.slides.length - 1) {
        return true
      }
      return false
    },

    buttonLabel () {
      if (this.isLastSlide) {
        return this.finalLabel || 'Lets’s start'
      }
      return this.mainButtonLabel || 'Next'
    },
  },

  methods: {
    setSwiperInstance (instance) {
      this.slider = instance
    },

    onClose () {
      this.$router.back()
    },

    async previousSlide () {
      if (this.slider) {
        this.slider.slidePrev()
      }
    },

    async buttonClick () {
      if (this.isLastSlide && !!this.url) {
        this.$router.push(this.url)
      } else if (this.isLastSlide) {
        this.$emit('onEnd')
      } else {
        if (this.slider) {
          this.slider.slideNext()
        }
      }
    },

    onNextEnd () {
      this.active = this.active + 1
    },

    onPrevEnd () {
      this.active = this.active - 1
    }
  }
}
</script>

<style>
.swiper-vh .swiper-pagination {
  top: 82vh !important;
}
.swiper-pagination {
  top: 5vh !important;
}
.swiper-pagination .swiper-pagination-bullet{
  background: #1C1C1B !important;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #C8102E !important;
}
</style>
